import * as React from "react";
import { Link } from "gatsby";

import logo from "../img/logo.svg";
import facebook from "../img/social/facebook.svg";
import twitter from "../img/social/twitter.svg";
import linkedin from "../img/social/linkedin.svg";
import github from "../img/social/github.svg";
import pinterest from "../img/social/pinterest.svg";

const Footer = class extends React.Component {
  render() {
    return (
      <footer className="footer has-background-black has-text-white-ter">
        <div className="content has-text-centered">
        <Link to="/">
        <img
            src={logo}
            alt="Predinfer"
            style={{ width: "10em", height: "2em" }}
          />
        </Link>          
        </div>
        <div className="content has-text-centered has-background-black has-text-white-ter">
          <div className="container has-background-black has-text-white-ter">
            <div style={{ maxWidth: "100vw" }} className="columns">
              <div className="column is-4">
                <section className="menu">
                  <ul className="menu-list">
                    <li>
                      <Link className="navbar-item" to="/blog">
                        Blog
                      </Link>
                    </li>
                    <li>
                      <Link className="navbar-item" to="/tags">
                        Tags
                      </Link>
                    </li>
                    <li>
                      <Link className="navbar-item" to="/newsletter">
                        Newsletter
                      </Link>
                    </li> 
                    <li>
                      <Link className="navbar-item" to="/contact">
                        Contact
                      </Link>
                    </li>       
                  </ul>
                </section>
              </div>
              <div className="column is-4 social">                
                <a title="linkedin" href="https://www.linkedin.com/company/predinfer" target="_blank"
                rel="noopener noreferrer">
                  <img
                    src={linkedin}
                    alt="linkedin"
                    style={{ width: "1em", height: "1em" }}
                  />
                </a>                
                <a title="twitter" href="https://twitter.com/predinfer" target="_blank"
                rel="noopener noreferrer">
                  <img
                    className="fas fa-lg"
                    src={twitter}
                    alt="Twitter"
                    style={{ width: "1em", height: "1em" }}
                  />
                </a>
                <a title="facebook" href="https://facebook.com/predinfer" target="_blank"
                rel="noopener noreferrer">
                  <img
                    src={facebook}
                    alt="Facebook"
                    style={{ width: "1em", height: "1em" }}
                  />
                </a>
                <a title="pinterest" href="https://www.pinterest.com/predinfer" target="_blank"
                rel="noopener noreferrer">
                  <img
                    src={pinterest}
                    alt="pinterest"
                    style={{ width: "1em", height: "1em" }}
                  />
                </a>
                <a title="github" href="https://github.com/predinfer" target="_blank"
                rel="noopener noreferrer">
                  <img
                    src={github}
                    alt="github"
                    style={{ width: "1em", height: "1em" }}
                  />
                </a>           
              </div>
            </div>
          </div>
        </div>
        <div class="content has-text-centered">
          © 2022-{new Date().getFullYear()} Predinfer
        </div>
      </footer>
    );
  }
};

export default Footer;
